import React from 'react';
import { ChevronDown } from 'lucide-react';

const TeamMemberCard = ({ member, onClick }) => {
  return (
    <div 
      onClick={() => onClick(member)}
      className="relative bg-[#1d2027] p-8 rounded-lg shadow-lg flex flex-col items-center transition-all duration-300 hover:shadow-xl hover:bg-[#2a3540] transform hover:-translate-y-2 hover:scale-105 cursor-pointer group flex-1"
    >
      <img 
        src={member.image} 
        alt={member.name} 
        className="w-40 h-40 rounded-full object-cover mb-6"
      />
      <div className="text-center">
        <h2 className="text-2xl font-chillax-bold text-[#FFFFFF] mb-2">{member.name}</h2>
        <p className="text-lg text-[#E67E22] font-chillax-medium mb-4">{member.role}</p>
        <p className="text-base text-[#f2f2f2] font-avenir">{member.bio}</p>
      </div>
      <div className="absolute bottom-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
        <ChevronDown 
          size={24} 
          color="#E67E22" 
          className="animate-bounce cursor-pointer" 
        />
      </div>
    </div>
  );
};

export default TeamMemberCard;