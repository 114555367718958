import React, { useEffect } from 'react';
import ContactHeader from './ContactHeader';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <ContactHeader />
      
      <div className="grid md:grid-cols-2 gap-12">
        {/* Contact Form Section */}
        <ContactForm />

        {/* Contact Information Section */}
        <ContactInfo />
      </div>
    </div>
  );
};

export default ContactUs;