import React from 'react';
import { X } from 'lucide-react';

const TeamMemberModal = ({ member, onClose }) => {
  if (!member) return null;

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-4 z-50"
      onClick={onClose}
    >
      <div
        className="bg-[#1d2027] rounded-xl max-w-3xl w-full max-h-[90vh] overflow-y-auto relative [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-track]:bg-[#1d2027] [&::-webkit-scrollbar-track]:rounded-r-xl [&::-webkit-scrollbar-thumb]:bg-[#3a4550] [&::-webkit-scrollbar-thumb]:rounded-full hover:[&::-webkit-scrollbar-thumb]:bg-[#4a5560] [&::-webkit-scrollbar-thumb]:transition-colors"
        onClick={(e) => e.stopPropagation()}
      >
        <button 
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
          className="sticky top-4 float-right mr-4 bg-[#2a3540] hover:bg-[#3a4550] text-[#f2f2f2] hover:text-white p-2 rounded-full shadow-lg transition-all duration-300 transform hover:scale-110"
        >
          <X size={24} />
        </button>
        <div className="p-8 clear-right">
          <MemberHeader member={member} />
          <MemberDetails member={member} />
        </div>
      </div>
    </div>
  );
};

const MemberHeader = ({ member }) => (
  <div className="flex items-start mb-6">
    <div className="flex items-center gap-6">
      <img 
        src={member.image} 
        alt={member.name}
        className="w-32 h-32 rounded-full object-cover"
      />
      <div>
        <h2 className="text-2xl font-chillax-bold text-[#FFFFFF]">{member.name}</h2>
        <p className="text-[#E67E22] font-chillax-medium">{member.role}</p>
      </div>
    </div>
  </div>
);

const MemberDetails = ({ member }) => (
  <div className="space-y-6 text-[#f2f2f2] font-avenir">
    <div>
      <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Background</h3>
      <p>{member.expandedInfo.background}</p>
    </div>

    {member.expandedInfo.expertise && (
      <DetailSection
        title="Areas of Expertise"
        items={member.expandedInfo.expertise}
      />
    )}

    {member.expandedInfo.professionalHighlights && (
      <DetailSection
        title="Professional Highlights"
        items={member.expandedInfo.professionalHighlights}
      />
    )}

    {member.expandedInfo.professionalHistory && (
      <DetailSection
        title="Professional History"
        items={member.expandedInfo.professionalHistory}
      />
    )}

    {member.expandedInfo.education && Array.isArray(member.expandedInfo.education) ? (
      <DetailSection
        title="Education"
        items={member.expandedInfo.education}
      />
    ) : member.expandedInfo.education && (
      <div>
        <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Education</h3>
        <p>{member.expandedInfo.education}</p>
      </div>
    )}

    {member.expandedInfo.languages && (
      <div>
        <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">Languages</h3>
        <ul className="list-disc pl-6 space-y-2">
          {member.expandedInfo.languages.map((lang, idx) => (
            <li key={idx}>
              <strong>{lang.language}:</strong> {lang.proficiency}
            </li>
          ))}
        </ul>
      </div>
    )}

    <DetailSection
      title="Key Responsibilities"
      items={member.expandedInfo.responsibilities}
    />
  </div>
);

const DetailSection = ({ title, items }) => (
  <div>
    <h3 className="text-lg font-chillax-bold text-[#FFFFFF] mb-2">{title}</h3>
    <ul className="list-disc pl-6 space-y-2">
      {items.map((item, idx) => (
        <li key={idx}>{item}</li>
      ))}
    </ul>
  </div>
);

export default TeamMemberModal;