import React, { useEffect } from 'react';
import Header from './Header';
import ApplicationCard from './ApplicationCard';
import FutureSection from './FutureSection';
import FAQSection from './FAQSection';
import CTASection from './CTASection';
import { applications } from './applicationsData';

const ExoskeletonApplications = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-6 py-24 max-w-7xl">
      <Header />
      
      {applications.map((app, index) => (
        <ApplicationCard 
          key={index} 
          application={app} 
          index={index} 
        />
      ))}

      <FutureSection />
      <FAQSection />
      <CTASection />
    </div>
  );
};

export default ExoskeletonApplications;