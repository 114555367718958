import React from 'react';
import { Phone, Mail, MapPin } from 'lucide-react';

const ContactInfo = () => {
  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-chillax-bold text-[#E67E22] mb-4">Contact Information</h2>
        <p className="text-[#f2f2f2] font-avenir mb-2 flex items-center">
          <Mail className="mr-2" size={20} /> info@newtonxr.com
        </p>
        <p className="text-[#f2f2f2] font-avenir mb-2 flex items-center">
          <Phone className="mr-2" size={20} /> +972 559572906
        </p>
        <p className="text-[#f2f2f2] font-avenir flex items-center">
          <MapPin className="mr-2" size={20} /> Tel Aviv
        </p>
      </div>
      <div>
        <h2 className="text-2xl font-chillax-bold text-[#E67E22] mb-4">Business Hours</h2>
        <p className="text-[#f2f2f2] font-avenir">Sunday - Thursday: 9:00 AM - 6:00 PM</p>
        <p className="text-[#f2f2f2] font-avenir">Friday - Saturday: Closed</p>
      </div>
      <div className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-6 rounded-xl">
        <h2 className="text-2xl font-chillax-bold text-[#E67E22] mb-4">Get in Touch</h2>
        <p className="text-[#f2f2f2] font-avenir">
          Whether you're looking to revolutionize your industry with ExoSuit VR or simply want to learn more about our technology, we're here to assist you. Don't hesitate to reach out!
        </p>
      </div>
    </div>
  );
};

export default ContactInfo;