import React from 'react';

const ContactHeader = () => {
  return (
    <>
      <h1 className="text-5xl font-chillax-bold mb-6 text-white leading-tight">Contact Us</h1>
      <p className="text-xl font-avenir text-[#f2f2f2] mb-12 max-w-3xl">
        Have questions about ExoSuit VR or want to explore how our technology can benefit your industry? We're here to help.
      </p>
    </>
  );
};

export default ContactHeader;