import React from 'react';
import { Zap, Layers, Cpu, Globe } from 'lucide-react';
import { motion } from 'framer-motion';

const TechnologiesSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 5 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4, ease: 'easeOut' },
    },
  };

  const technologies = [
    {
      icon: <Zap size={32} className="text-[#E67E22]" />,
      title: 'Patented Dual-Piston Locomotion System',
      description:
        "The dual-piston locomotion system is at the heart of ExoSuit VR's groundbreaking capabilities. By independently adjusting the ground level under each foot, it allows users to naturally walk, climb, and navigate virtual terrains with unprecedented realism.",
      points: [
        {
          title: 'Natural Movement Simulation',
          description:
            'Walk up and down virtual stairs, hike over uneven landscapes, and experience different terrains as the dual pistons adjust to each footstep.',
        },
        {
          title: 'Balance and Stability',
          description:
            'The system dynamically adapts to your center of gravity, maintaining balance and preventing falls during complex movements.',
        },
        {
          title: 'Sitting and Interaction',
          description:
            'Sit in virtual chairs or interact with environments in a way that feels physically authentic, enhancing immersion and comfort.',
        },
      ],
    },
    {
      icon: <Layers size={32} className="text-[#E67E22]" />,
      title: 'Full-Body Exoskeleton Interaction',
      description:
        'Our full-body exoskeleton extends beyond locomotion, providing haptic feedback and resistance to simulate physical interactions with virtual objects and environments.',
      points: [
        {
          title: 'Realistic Force Feedback',
          description:
            'Feel the weight of a virtual object, the recoil of a virtual weapon, or the resistance when pushing against a virtual barrier.',
        },
        {
          title: 'Precise Motion Tracking',
          description:
            'The exoskeleton accurately captures your movements, translating them into the virtual world with minimal latency.',
        },
        {
          title: 'Ergonomic Design',
          description:
            'Adjustable components ensure comfort for users of various sizes, promoting natural movement and reducing fatigue during extended sessions.',
        },
      ],
    },
    {
      icon: <Cpu size={32} className="text-[#E67E22]" />,
      title: 'Adaptive AI Technology',
      description:
        "ExoSuit VR incorporates advanced AI algorithms that adapt to your movements in real-time. The AI adjusts force feedback and resistance based on your actions, ensuring a personalized and responsive experience. Whether you're climbing, lifting, or navigating complex environments, the AI enhances realism by providing appropriate haptic feedback.",
    },
    {
      icon: <Globe size={32} className="text-[#E67E22]" />,
      title: 'Comprehensive Patent Coverage',
      description:
        'Our innovative combination of the dual-piston system and full-body exoskeleton is fully patented. This comprehensive patent coverage protects the unique integration of our technologies, ensuring that ExoSuit VR remains at the forefront of immersive VR solutions.',
    },
  ];

  return (
    <section className="mb-24">
      <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">Core Technologies</h2>
      <div className="space-y-8">
        {technologies.map((tech, index) => (
          <motion.div
            key={index}
            className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-8 rounded-2xl shadow-lg"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: '-50px' }}
            transition={{ delay: index * 0.1 }}
          >
            <div className="flex items-center gap-4 mb-4">
              <div className="flex-shrink-0">{tech.icon}</div>
              <h3 className="text-2xl font-chillax-bold text-[#FFFFFF]">{tech.title}</h3>
            </div>
            <div>
              <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed mb-6">
                {tech.description}
              </p>
              {tech.points && (
                <ul className="space-y-4">
                  {tech.points.map((point, idx) => (
                    <li key={idx} className="text-[#f2f2f2] font-avenir">
                      <span className="font-semibold">{point.title}: </span>
                      {point.description}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default TechnologiesSection;