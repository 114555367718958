import React from 'react';
import { motion } from 'framer-motion';

const ProductHero = () => {
  return (
    <>
      <motion.h1 
        className="text-5xl md:text-6xl font-chillax-bold mb-6 text-white leading-tight"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        ExoSuit VR: The Pinnacle of Physical Immersion
      </motion.h1>
      <motion.p 
        className="text-xl md:text-2xl font-avenir text-[#f2f2f2] mb-12 max-w-3xl"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Discover how ExoSuit VR, combining a patented dual-piston locomotion system with a full-body exoskeleton, 
        is setting new standards in virtual reality. Experience true physical interaction with digital worlds, 
        whether for professional training or groundbreaking entertainment.
      </motion.p>
    </>
  );
};

export default ProductHero;