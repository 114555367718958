import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const IntroductionSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    },
  };

  return (
    <section className="bg-gradient-to-r from-[#1d2027] to-[#2a3540] py-24">
      <div className="container mx-auto px-4 md:px-6 relative">
        <motion.div
          className="text-center max-w-4xl mx-auto"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h1 className="text-5xl md:text-7xl font-chillax-bold text-white mb-8 leading-tight">
            Newton: Redefining Virtual Reality Interaction
          </h1>
          <p className="text-xl md:text-2xl text-[#f2f2f2] font-avenir mb-12 leading-relaxed">
            Newton is bridging the gap between physical and virtual worlds with revolutionary VR exoskeleton technology. Our innovative solutions allow users to physically interact with digital environments, transforming industries ranging from professional training to immersive entertainment.
          </p>
          <Link to="/product">
            <motion.button
              className="bg-[#E67E22] hover:bg-[#D35400] text-white text-lg font-chillax-bold py-4 px-8 rounded-full transition duration-300"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Explore Our Technology
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default IntroductionSection;