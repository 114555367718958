import React from 'react';
import { Link } from 'react-router-dom';
import { Zap, Globe, Users, Briefcase, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';

const FutureImpactSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const impacts = [
    {
      icon: <Zap size={32} className="text-[#E67E22]" />,
      title: 'Advanced Professional Training',
      description:
        'Enable high-stakes training in hyper-realistic virtual environments, empowering professionals to master their skills without real-world risks.',
    },
    {
      icon: <Globe size={32} className="text-[#E67E22]" />,
      title: 'Revolutionary Product Development',
      description:
        'Revolutionize product development by allowing engineers and designers to physically interact with virtual prototypes, accelerating innovation and reducing time to market.',
    },
    {
      icon: <Users size={32} className="text-[#E67E22]" />,
      title: 'Immersive Educational Experiences',
      description:
        'Transform education with fully immersive VR experiences that enhance engagement and significantly improve learning outcomes.',
    },
    {
      icon: <Briefcase size={32} className="text-[#E67E22]" />,
      title: 'Next-Generation Entertainment',
      description:
        'Elevate entertainment by seamlessly blending reality with virtual worlds, delivering unmatched levels of immersion and excitement.',
    },
  ];

  return (
    <section className="mb-32">
      <motion.h2
        className="text-4xl font-chillax-bold text-white mb-12 text-center"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        Pioneering the Next Frontier of Immersive Technology
      </motion.h2>

      <motion.p
        className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-16 max-w-4xl mx-auto text-center"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ delay: 0.2 }}
      >
        At Newton, we're not just envisioning the future—we're actively creating it. Our
        groundbreaking technologies have the potential to revolutionize multiple industries,
        transforming the way we live, work, and play.
      </motion.p>

      <div className="grid md:grid-cols-2 gap-10 mb-16">
        {impacts.map((impact, index) => (
          <motion.div
            key={index}
            className="bg-[#2a3540] p-8 rounded-2xl shadow-lg"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          >
            <div className="flex items-center mb-4">
              {impact.icon}
              <h3 className="text-2xl font-chillax-bold ml-4 text-white">{impact.title}</h3>
            </div>
            <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed">
              {impact.description}
            </p>
          </motion.div>
        ))}
      </div>

      <div className="text-center">
        <Link to="/exoskeleton-applications">
          <motion.button
            className="bg-[#E67E22] text-white px-8 py-4 rounded-full transition duration-300 flex items-center text-lg font-semibold mx-auto shadow-lg hover:shadow-xl"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Explore Exoskeleton Applications <ChevronRight className="ml-2" size={20} />
          </motion.button>
        </Link>
      </div>
    </section>
  );
};

export default FutureImpactSection;