import React from 'react';
import { motion } from 'framer-motion';

const EngineeringSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  };

  return (
    <section>
      <motion.h2 
        className="text-4xl font-chillax-bold text-white mb-12 text-center"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        Beyond Boundaries: The Engineering Behind ExoSuit VR
      </motion.h2>
      <div className="space-y-10">
        <motion.div
          className="bg-[#2a3540] p-8 rounded-2xl shadow-lg"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h3 className="text-2xl font-chillax-bold mb-4 text-white">Patented Dual-Piston System</h3>
          <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed mb-6">
            At the core of ExoSuit VR's locomotion is our dual-piston system that adjusts ground levels independently for each foot. This system:
          </p>
          <ul className="list-disc list-inside text-[#f2f2f2] font-avenir space-y-2">
            <li>Allows for natural walking, climbing stairs, hiking uphill or downhill, and navigating uneven terrains with realistic resistance.</li>
            <li>Maintains user stability and balance by dynamically adjusting to the user's center of gravity.</li>
            <li>Replaces the need for cumbersome 360° treadmills, offering a more intuitive and immersive solution for VR locomotion.</li>
          </ul>
        </motion.div>
        <motion.div
          className="bg-[#2a3540] p-8 rounded-2xl shadow-lg"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h3 className="text-2xl font-chillax-bold mb-4 text-white">Full-Body Exoskeleton Interaction</h3>
          <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed mb-6">
            The ExoSuit VR's body exoskeleton is engineered to:
          </p>
          <ul className="list-disc list-inside text-[#f2f2f2] font-avenir space-y-2">
            <li>Provide authentic force feedback and resistance, letting you feel the weight and texture of virtual objects.</li>
            <li>Accurately translate user movements into VR, enabling natural actions like crouching, leaning, and jumping without disorientation.</li>
            <li>Enhance realism for scenarios such as operating heavy machinery or performing combat maneuvers with realistic forces and resistances.</li>
          </ul>
        </motion.div>
      </div>
    </section>
  );
};

export default EngineeringSection;