import React from 'react';
import { motion } from 'framer-motion';

const VisionHeader = () => {
  return (
    <>
      <motion.h1
        className="text-5xl sm:text-6xl md:text-7xl font-chillax-bold mb-8 text-[#E67E22] leading-tight text-center"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Redefining the Future of VR Interaction
      </motion.h1>
      <motion.p
        className="text-xl sm:text-2xl font-avenir text-[#f2f2f2] mb-16 max-w-3xl mx-auto leading-relaxed text-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        At Newton, we erase the boundaries between physical and digital realms, empowering you to
        interact with virtual worlds like never before.
      </motion.p>
    </>
  );
};

export default VisionHeader;