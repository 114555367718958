import { Shield, Briefcase, Gamepad, GraduationCap, HeartPulse, HardHat, Zap, Globe } from 'lucide-react';
import React from 'react';

export const applications = [
  {
    icon: <Shield size={32} className="text-[#E67E22]" />,
    title: 'Military and Security Training',
    description:
      'ExoSuit VR is transforming military and security training by providing hyper-realistic force feedback in virtual combat and tactical scenarios. Trainees can now physically experience the weight of their equipment, the recoil of their weapons, and the resistance of different terrains, leading to unparalleled immersion and effectiveness in training.',
    details: [
      'Weapon Handling: Feel the exact weight, balance, and recoil of various firearms and melee weapons, enhancing muscle memory and weapon proficiency.',
      'Terrain Navigation: Navigate through virtual environments with realistic resistance and feedback, simulating mud, sand, and uneven terrains.',
      'Close Combat Training: Engage in hand-to-hand combat with force feedback that simulates strikes, blocks, and holds, improving combat readiness.',
      'Equipment Interaction: Interact with virtual gear as if it\'s real—feel the heft of a ballistic shield or the resistance of opening a heavy door.',
      'Obstacle Course Simulation: Train on virtual obstacle courses with physical feedback—climb walls, crawl under obstacles, and jump gaps with realistic force sensations.',
    ],
  },
  {
    icon: <Briefcase size={32} className="text-[#E67E22]" />,
    title: 'Industrial Manufacturing',
    description:
      'ExoSuit VR is redefining industrial manufacturing by enabling workers and engineers to physically interact with virtual prototypes, machinery, and environments. This leads to enhanced design processes, improved training, and greater safety, all while reducing costs and production downtime.',
    details: [
      'Virtual Prototyping: Physically test virtual prototypes for ergonomics, assembly feasibility, and user experience before committing to expensive physical models.',
      'Assembly Line Training: Train workers on virtual assembly lines with realistic force feedback, reducing errors and improving efficiency on the actual line.',
      'Machinery Operation: Operate virtual heavy machinery with accurate force sensations, preparing operators for the feel and response of real equipment.',
      'Ergonomic Assessment: Assess and optimize workstation designs by experiencing them in VR, ensuring worker comfort and reducing the risk of repetitive strain injuries.',
      'Safety Procedure Training: Practice emergency protocols in a safe virtual environment, feeling the force required to operate safety equipment and machinery.',
      'Maintenance and Repair: Simulate maintenance tasks with realistic resistance and force, allowing technicians to practice complex procedures without risking equipment.',
    ],
  },
  {
    icon: <Gamepad size={32} className="text-[#E67E22]" />,
    title: 'Gaming and Entertainment',
    description:
      'ExoSuit VR is pushing the boundaries of gaming and entertainment by allowing players to physically feel and interact with virtual worlds. Our technology brings a new level of immersion, where every action has a tangible sensation, making virtual experiences more engaging than ever before.',
    details: [
      'Immersive Combat: Feel every punch, kick, and weapon strike, adding a new layer of realism to fighting games.',
      'Sports Simulation: Experience the physicality of sports—feel the impact of a football tackle, the swing of a golf club, or the finesse required in a tennis match.',
      'Adventure Gaming: Physically climb, swim, and navigate through virtual environments, feeling textures and resistance as you explore.',
      'Racing Simulation: Sense G-forces, vibrations, and impacts in racing games, enhancing the thrill of high-speed competition.',
      'Virtual Theme Parks: Immerse yourself in interactive experiences like exploring fantasy worlds or becoming a hero in your favorite universe.',
      'Musical Instruments: Play virtual instruments with tactile feedback—feel the strings of a guitar or the keys of a piano, enhancing musical gameplay.',
      'Extreme Sports: Safely experience the adrenaline of activities like snowboarding or rock climbing with realistic force sensations.',
    ],
  },
  {
    icon: <GraduationCap size={32} className="text-[#E67E22]" />,
    title: 'Education and Training',
    description:
      'ExoSuit VR is revolutionizing education by enabling students to physically interact with educational content in a virtual setting. By adding the sense of touch to learning, complex concepts become tangible, leading to deeper understanding and retention.',
    details: [
      'Science Education: Conduct virtual experiments with physical feedback, manipulating forces and observing reactions safely.',
      'Historical Reenactments: Experience history by handling virtual artifacts, feeling the weight and texture of items from the past.',
      'Vocational Training: Practice trades like welding, carpentry, and electrical work with realistic tool feedback, preparing students for real-world jobs.',
      'Architectural Walkthroughs: Walk through and interact with building designs, testing functionality and ergonomics before construction.',
      'Art and Design: Sculpt and create art in VR with tactile sensations, enhancing creativity and understanding of materials.',
    ],
  },
  {
    icon: <HeartPulse size={32} className="text-[#E67E22]" />,
    title: 'Healthcare and Medical Training',
    description:
      'ExoSuit VR is transforming healthcare by providing realistic simulations for medical training and patient rehabilitation. Practitioners can hone their skills with tactile feedback, and patients can engage in interactive therapies that promote recovery.',
    details: [
      'Surgical Training: Perform virtual surgeries with force feedback, feeling the resistance of tissues and improving precision and technique.',
      'Dental Procedures: Practice dental work with realistic sensations, enhancing the training of dentists and hygienists.',
      'Physical Therapy: Assist patients in performing exercises with adaptive resistance, promoting muscle development and mobility.',
      'Patient Diagnostics: Simulate physical exams, feeling for abnormalities in virtual patients, improving diagnostic skills.',
      'Emergency Response Training: Experience high-pressure scenarios with physical feedback, preparing responders for real emergencies.',
      'Neurological Assessments: Practice assessments with tactile interactions, enhancing the ability to detect subtle neurological signs.',
      'Obstetrics Training: Simulate childbirth and prenatal procedures with realistic force feedback, improving care for mothers and infants.',
    ],
  },
  {
    icon: <HardHat size={32} className="text-[#E67E22]" />,
    title: 'Architecture and Construction',
    description:
      'ExoSuit VR is innovating the architecture and construction industry by allowing professionals to physically interact with designs and construction processes in a virtual environment. This leads to better design decisions, improved safety, and more efficient project management.',
    details: [
      'Virtual Walkthroughs: Physically navigate through building designs, assessing spatial relationships and ergonomics.',
      'Construction Planning: Simulate construction tasks with realistic force feedback, identifying potential issues before they arise.',
      'Safety Training: Experience and practice safety protocols in a controlled virtual setting, reducing workplace accidents.',
      'Material Testing: Feel the textures and properties of virtual materials, aiding in selection and design decisions.',
      'Collaborative Design: Work with teams in VR to modify and interact with designs, improving communication and reducing errors.',
      'Equipment Operation: Practice using construction machinery with force feedback, enhancing operator skills without risks.',
      'Client Presentations: Provide clients with immersive experiences of their projects, increasing satisfaction and approval rates.',
    ],
  },
  {
    icon: <Zap size={32} className="text-[#E67E22]" />,
    title: 'Energy and Utilities',
    description:
      'ExoSuit VR is enhancing the energy and utilities sector by providing realistic simulations for training, maintenance, and operations. Workers can safely interact with virtual environments that replicate hazardous conditions, improving preparedness and efficiency.',
    details: [
      'Power Plant Operations: Simulate plant controls and systems with force feedback, training operators without risk.',
      'Offshore Oil Rig Training: Experience the challenges of offshore work, preparing workers for the physical demands.',
      'Renewable Energy Installation: Practice installing and maintaining equipment like wind turbines and solar panels with tactile feedback.',
      'Utility Line Maintenance: Simulate high-risk tasks such as repairing live wires, enhancing safety and skill.',
      'Emergency Response Drills: Prepare for incidents like gas leaks or explosions in a safe virtual environment.',
      'Equipment Handling: Feel the weight and balance of tools and components, improving handling skills and reducing accidents.',
    ],
  },
  {
    icon: <Globe size={32} className="text-[#E67E22]" />,
    title: 'Aerospace and Aviation',
    description:
      'ExoSuit VR is propelling the aerospace and aviation industries forward by offering unparalleled training and simulation experiences. With realistic force feedback, pilots, astronauts, and engineers can train more effectively, leading to safer skies and successful missions.',
    details: [
      'Pilot Training: Feel realistic aircraft controls with force feedback, improving handling skills and reaction times.',
      'Spacecraft Operation: Simulate zero-gravity environments and spacecraft controls with haptic feedback, experiencing the sensation of weightlessness for astronaut training.',
      'Maintenance and Repair: Practice intricate repairs on aircraft and spacecraft components with realistic resistance and precision.',
      'Emergency Procedures: Experience simulated in-flight emergencies with physical feedback, enhancing preparedness.',
      'Payload Handling: Train in loading and securing cargo with accurate weight and balance sensations.',
      'Spacewalk Simulations: Practice extravehicular activities (EVAs) with force feedback, crucial for mission success.',
    ],
  },
];

export const faqData = [
  {
    question: 'How does the force feedback in ExoSuit VR work?',
    answer: 'ExoSuit VR uses a sophisticated system of actuators and sensors distributed throughout the suit to provide realistic force feedback. When you interact with a virtual object, the suit applies corresponding forces to your body, simulating the weight, texture, and resistance of the object. This is achieved through a combination of our proprietary dual-piston technology and advanced haptic generators, allowing for a wide range of force simulations from subtle textures to significant resistance.',
  },
  {
    question: 'Is the force feedback safe?',
    answer: 'Absolutely. Safety is our top priority in designing ExoSuit VR. The system is equipped with multiple safety features, including force limiters, emergency stop mechanisms, and real-time monitoring of user vital signs. The force feedback is calibrated to never exceed safe levels, and the system constantly adjusts based on user responses. Additionally, all our products undergo rigorous safety testing and comply with international standards for wearable technology.',
  },
  {
    question: 'Can ExoSuit VR be used with existing VR content?',
    answer: 'While ExoSuit VR can enhance the experience of standard VR content by providing basic haptic feedback, it truly shines with applications specifically designed to take advantage of its force feedback capabilities. We provide a comprehensive Software Development Kit (SDK) that allows developers to create fully compatible content, leveraging all aspects of our force feedback technology. We also work with content creators to adapt existing VR experiences to fully utilize ExoSuit VR\'s capabilities.',
  },
  {
    question: 'How realistic is the force feedback?',
    answer: 'The force feedback in ExoSuit VR is highly realistic, providing a level of immersion that users often describe as "uncannily real". Our technology can simulate a wide range of sensations, from the subtle texture of different materials to the substantial resistance of heavy objects. While it\'s important to note that the experience is optimized for VR and may not exactly replicate all real-world forces, many users report that the sensations are convincing enough to trigger genuine physical and emotional responses.',
  },
  {
    question: 'Can ExoSuit VR be used for physical rehabilitation?',
    answer: 'Yes, ExoSuit VR has significant potential in physical rehabilitation. By providing controlled force feedback, it can assist in movement therapy, strength training, and motor skill recovery. The system can be programmed to provide varying levels of resistance or assistance, allowing for personalized therapy programs. However, it\'s important to note that any medical use should be under the supervision of qualified healthcare professionals. We work closely with medical institutions to develop specific rehabilitation protocols and ensure the safe and effective use of our technology in therapeutic settings.',
  },
  {
    question: 'What kind of maintenance does ExoSuit VR require?',
    answer: 'ExoSuit VR is designed for durability and ease of maintenance. Regular software updates are provided to ensure optimal performance and security. For hardware, we recommend periodic inspections and calibrations, which can be performed by our certified technicians or by your own team after completing our training program. The frequency of maintenance depends on the intensity of use, but typically, a full check-up is recommended every 6 months for high-use scenarios. We also provide comprehensive care packages that include regular maintenance, priority support, and replacement parts to ensure your ExoSuit VR remains in peak condition.',
  },
];