import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const CallToAction = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    },
  };

  return (
    <section className="py-24 bg-[#1d2027]">
      <div className="container mx-auto px-4 md:px-6 text-center">
        <motion.div
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          <h2 className="text-3xl md:text-4xl font-chillax-bold mb-8 text-white">
            Ready to Experience the Future?
          </h2>
          <p className="text-xl mb-12 text-[#f2f2f2] font-avenir max-w-3xl mx-auto leading-relaxed">
            Join us in exploring a new dimension of virtual reality where physical sensations and digital environments converge. Discover how Newton's technology can transform entertainment, training, and more.
          </p>
          <Link to="/contact-us">
            <motion.button
              className="bg-[#E67E22] hover:bg-[#D35400] text-white px-8 py-4 rounded-full text-lg font-chillax-bold transition-all duration-300 inline-block"
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Get in Touch
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default CallToAction;