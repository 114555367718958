import React from 'react';
import { Send } from 'lucide-react';
import { motion } from 'framer-motion';
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";

const ContactForm = () => {
  const [result, setResult] = React.useState("");
  const { register, handleSubmit, reset } = useForm();

  const accessKey = process.env.REACT_APP_WEB3FORMS_ACCESS_KEY;

  const { submit: onSubmit } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: "Newton XR Contact Form",
      subject: "New Contact Message from Newton XR Website",
    },
    onSuccess: (msg, data) => {
      setResult("Form Submitted Successfully");
      reset();
    },
    onError: (msg, data) => {
      setResult("Failed to submit form. Please try again later.");
    },
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <input type="hidden" value={accessKey} {...register("access_key")} />
        <input type="hidden" value="https://web3forms.com/success" {...register("redirect")} />

        <div>
          <label htmlFor="name" className="block text-[#f2f2f2] font-avenir mb-2">Name</label>
          <input
            type="text"
            id="name"
            {...register("name", { required: true })}
            className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
            placeholder="Your name"
          />
        </div>
        <div>
          <label htmlFor="email" className="block text-[#f2f2f2] font-avenir mb-2">Email</label>
          <input
            type="email"
            id="email"
            {...register("email", { required: true })}
            className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
            placeholder="your@email.com"
          />
        </div>
        <div>
          <label htmlFor="subject" className="block text-[#f2f2f2] font-avenir mb-2">Subject</label>
          <input
            type="text"
            id="subject"
            {...register("subject", { required: true })}
            className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
            placeholder="Your subject"
          />
        </div>
        <div>
          <label htmlFor="message" className="block text-[#f2f2f2] font-avenir mb-2">Message</label>
          <textarea
            id="message"
            {...register("message", { required: true })}
            rows="4"
            className="w-full px-4 py-2 rounded-md bg-[#2a3540] text-[#f2f2f2] focus:outline-none focus:ring-2 focus:ring-[#E67E22]"
            placeholder="Your message"
          ></textarea>
        </div>
        <motion.button
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-[#E67E22] text-white px-6 py-3 rounded-md font-chillax-bold flex items-center"
        >
          Send Message <Send className="ml-2" size={20} />
        </motion.button>
      </form>
      <span className="mt-4 text-[#f2f2f2] font-avenir">{result}</span>
    </div>
  );
};

export default ContactForm;