import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const CTASection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  return (
    <motion.div
      className="mt-24 text-center"
      variants={fadeIn}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: '-100px' }}
    >
      <h2 className="text-4xl font-chillax-bold text-white mb-6">Ready to Feel the Virtual World?</h2>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
        Whether you're looking to enhance training programs, create more immersive entertainment
        experiences, or explore new frontiers in virtual interaction, ExoSuit VR provides the
        platform to bring physical sensation to virtual reality. Our technology is not just a
        product; it's a gateway to a new dimension of digital interaction.
      </p>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
        Join us in shaping the future of virtual reality. Experience the power of touch in VR and
        unlock new possibilities for your industry or application.
      </p>
      <Link to="/contact-us">
        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="bg-[#E67E22] text-white py-3 px-6 rounded-md font-chillax-bold"
        >
          Contact Us
        </motion.button>
      </Link>
    </motion.div>
  );
};

export default CTASection;