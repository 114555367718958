import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';

const Header = () => {
  return (
    <>
      <Link
        to="/product"
        className="flex items-center text-[#E67E22] mb-8 hover:underline transition-colors duration-300"
      >
        <ArrowLeft className="mr-2" size={20} />
        Back to Product Overview
      </Link>

      <motion.h1
        className="text-5xl font-chillax-bold mb-6 leading-tight"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        ExoSuit VR: Detailed Insights into Revolutionary Immersion
      </motion.h1>
      <motion.p
        className="text-xl font-avenir text-[#f2f2f2] mb-16 max-w-3xl leading-relaxed"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Dive deeper into the groundbreaking technologies that make ExoSuit VR the ultimate solution
        for physical interaction in virtual environments. Discover how our integrated systems
        redefine immersion, realism, and user experience in virtual reality.
      </motion.p>
    </>
  );
};

export default Header;