import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const FinalCTASection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <motion.div
      className="mt-24 p-10 bg-[#2a3540] rounded-2xl shadow-xl"
      variants={fadeIn}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
    >
      <h3 className="text-3xl font-chillax-bold mb-8 text-white">
        Join Us in Redefining Human-Machine Interaction
      </h3>
      <div className="space-y-6 text-lg text-[#f2f2f2] font-avenir leading-relaxed">
        <p>
          At Newton, we're not just crafting technology—we're reshaping the very fabric of human
          interaction with digital worlds. Our cutting-edge exoskeleton technology, coupled with
          advanced AI and haptic feedback systems, transcends the boundaries of virtual and
          augmented reality. We envision a future where our innovations amplify human
          capabilities across all sectors, seamlessly intertwining digital and physical realms.
        </p>
        <p>
          We invite you to join us on this transformative journey and be part of the next great
          leap in immersive technology.
        </p>
      </div>
      <Link to="/contact-us">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="mt-10 bg-[#E67E22] text-white py-4 px-8 rounded-full text-lg font-chillax-bold shadow-lg hover:shadow-xl flex items-center"
        >
          Join Our Vision
        </motion.button>
      </Link>
    </motion.div>
  );
};

export default FinalCTASection;