import React, { useEffect } from 'react';
import Header from './Header';
import TechnologiesSection from './TechnologiesSection';
import TechnicalInsights from './TechnicalInsights';
import CustomizationSection from './CustomizationSection';

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] text-white">
      <div className="container mx-auto px-6 py-24 max-w-7xl">
        <Header />
        <TechnologiesSection />
        <TechnicalInsights />
        <CustomizationSection />
      </div>
    </div>
  );
};

export default ProductDetails;