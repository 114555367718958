import React, { useEffect } from 'react';
import HeroSection from './HeroSection';
import IntroductionSection from './IntroductionSection';
import ExplanationSection from './ExplanationSection';
import InnovationsSection from './InnovationsSection';
import CallToAction from './CallToAction';

const Home = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroSection />
      <IntroductionSection />
      <ExplanationSection />
      <InnovationsSection />
      <CallToAction />
    </>
  );
};

export default Home;