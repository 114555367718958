import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const ProductCTA = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  };

  return (
    <motion.div 
      className="mt-24 p-10 bg-[#2a3540] rounded-2xl shadow-xl"
      variants={fadeIn}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "-100px" }}
    >
      <h3 className="text-3xl font-chillax-bold mb-6 text-white">Experience a New Reality with ExoSuit VR</h3>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
        Newton's patented ExoSuit VR technology seamlessly melds the physical and virtual worlds, 
        delivering realistic locomotive feedback, full-body immersion, and interactive object manipulation. 
        Whether for high-stakes training or immersive entertainment, ExoSuit VR heightens the sense of 
        presence and engagement beyond what conventional VR systems can offer.
      </p>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
        Join us in redefining the boundaries of virtual reality. Reach out today to learn how ExoSuit VR 
        can transform your industry, training programs, or entertainment experiences.
      </p>
      <Link to="/contact-us">
        <motion.button
          className="bg-[#E67E22] text-white px-8 py-4 rounded-full text-lg font-chillax-bold transition-all duration-300"
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          Contact Us
        </motion.button>
      </Link>
    </motion.div>
  );
};

export default ProductCTA;