import React, { useState, useEffect } from 'react';
import TeamHeader from './TeamHeader';
import TeamGrid from './TeamGrid';
import TeamMemberModal from './TeamMemberModal';
import { teamMembers } from './teamData';

const Team = () => {
  const [selectedMember, setSelectedMember] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container mx-auto px-6 py-24">
      <TeamHeader />
      <TeamGrid 
        members={teamMembers} 
        onMemberClick={setSelectedMember} 
      />
      <TeamMemberModal 
        member={selectedMember} 
        onClose={() => setSelectedMember(null)} 
      />
    </div>
  );
};

export default Team;