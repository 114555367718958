import React from 'react';
import TeamMemberCard from './TeamMemberCard';

const TeamGrid = ({ members, onMemberClick }) => {
  return (
    <div className="flex flex-col lg:flex-row gap-8 max-w-7xl mx-auto">
      {members.map((member, index) => (
        <TeamMemberCard 
          key={index} 
          member={member} 
          onClick={onMemberClick}
        />
      ))}
    </div>
  );
};

export default TeamGrid;