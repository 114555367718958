import React from 'react';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

const ApplicationCard = ({ application, index }) => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  // Determine section ID based on title
  const getSectionId = (title) => {
    switch (title) {
      case 'Military and Security Training':
        return 'military-security';
      case 'Industrial Manufacturing':
        return 'industrial-manufacturing';
      case 'Gaming and Entertainment':
        return 'gaming-entertainment';
      case 'Healthcare and Medical Training':
        return 'healthcare-medical';
      case 'Aerospace and Aviation':
        return 'aerospace-aviation';
      default:
        return '';
    }
  };

  return (
    <motion.div
      id={getSectionId(application.title)}
      className="mb-20 bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-8 rounded-2xl shadow-lg"
      variants={fadeIn}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: '-100px' }}
      transition={{ delay: index * 0.1 }}
    >
      <div className="flex items-center mb-4">
        {application.icon}
        <h2 className="text-3xl font-chillax-bold ml-4 text-[#FFFFFF]">{application.title}</h2>
      </div>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-6">
        {application.description}
      </p>
      <h3 className="text-xl font-chillax-bold text-[#E67E22] mb-4">
        Key Force Feedback Applications:
      </h3>
      <ul className="space-y-4 text-[#f2f2f2] font-avenir mb-6">
        {application.details.map((detail, idx) => (
          <li key={idx} className="flex items-start">
            <ArrowRight className="text-[#E67E22] mr-3 mt-1 flex-shrink-0" size={16} />
            <span>{detail}</span>
          </li>
        ))}
      </ul>
    </motion.div>
  );
};

export default ApplicationCard;