import React from 'react';
import { motion } from 'framer-motion';

const VisionMissionSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  return (
    <section className="mb-32">
      <div className="grid md:grid-cols-2 gap-12 lg:gap-16">
        <motion.div
          className="bg-[#2a3540] p-8 rounded-2xl shadow-lg border-l-4 border-[#E67E22]"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
          <h2 className="text-3xl font-chillax-bold mb-6 text-[#E67E22]">Our Vision</h2>
          <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
            We aim to be at the forefront of merging digital and physical realms, making our
            exoskeleton technology the benchmark for advanced human-machine interaction. Within
            the next decade, we envision Newton as a global leader driving innovation,
            education, and human development through immersive virtual experiences.
          </p>
        </motion.div>
        <motion.div
          className="bg-[#2a3540] p-8 rounded-2xl shadow-lg border-l-4 border-[#E67E22]"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
        >
          <h2 className="text-3xl font-chillax-bold mb-6 text-[#E67E22]">Our Mission</h2>
          <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed">
            Newton's mission is to empower individuals and organizations to transcend physical
            limitations. Through our innovative exoskeleton, we provide unprecedented immersion
            in virtual reality, revolutionizing entertainment, education, and professional
            training via continuous innovation and strategic collaborations.
          </p>
        </motion.div>
      </div>
    </section>
  );
};

export default VisionMissionSection;