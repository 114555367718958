import React from 'react';
import { Lightbulb, User, Users, Shield, Award, BookOpen } from 'lucide-react';
import { motion } from 'framer-motion';

const CoreValuesSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.6 } },
  };

  const values = [
    {
      icon: Lightbulb,
      title: 'Innovative Spirit',
      description: 'Embracing creativity and pioneering ideas to drive transformative technologies.',
    },
    {
      icon: User,
      title: 'User-Centric Approach',
      description: 'Prioritizing the needs and experiences of our users in every decision we make.',
    },
    {
      icon: Users,
      title: 'Collaborative Excellence',
      description: 'Building strong partnerships and fostering teamwork to achieve collective success.',
    },
    {
      icon: Shield,
      title: 'Integrity and Transparency',
      description: 'Upholding honesty and openness, earning trust through ethical practices.',
    },
    {
      icon: Award,
      title: 'Pursuit of Excellence',
      description: 'Committed to delivering the highest quality in all our products and services.',
    },
    {
      icon: BookOpen,
      title: 'Continuous Growth',
      description: 'Cultivating a learning culture that adapts and evolves with changing landscapes.',
    },
  ];

  return (
    <section className="mb-32">
      <motion.h2
        className="text-4xl font-chillax-bold text-white mb-12 text-center"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        Our Core Values
      </motion.h2>
      <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
        {values.map((value, index) => (
          <motion.div
            key={index}
            className="bg-[#2a3540] p-6 rounded-xl shadow-lg text-center"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
          >
            <value.icon size={40} className="text-[#E67E22] mb-4 mx-auto" />
            <h3 className="text-xl font-chillax-bold mb-2 text-white">{value.title}</h3>
            <p className="text-sm text-[#f2f2f2] font-avenir">{value.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default CoreValuesSection;