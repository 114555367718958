import React from 'react';
import { motion } from 'framer-motion';

const TransformingIndustries = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0, 
      transition: { duration: 0.5, ease: "easeOut" } 
    },
  };

  const industries = [
    {
      title: "Advanced Professional Training",
      description: "ExoSuit VR elevates training across high-stakes industries by allowing professionals to physically engage with virtual simulations. Whether performing intricate surgical procedures or responding to hazardous emergencies, users can practice with realistic force feedback, significantly enhancing performance and reducing real-world risks."
    },
    {
      title: "Revolutionary R&D and Prototyping",
      description: "ExoSuit VR redefines the R&D process by enabling engineers and designers to physically interact with virtual prototypes. This hands-on approach dramatically reduces the need for physical models, leading to substantial cost savings, accelerated development cycles, and enhanced product refinement through real-time physical feedback."
    },
    {
      title: "Next-Gen Immersive Entertainment",
      description: "In the entertainment sector, ExoSuit VR opens new frontiers by allowing users to move and interact physically within virtual environments. Gamers can handle virtual weapons, tools, and artifacts with lifelike sensations, while amusement park visitors experience levels of immersion and engagement previously unimaginable."
    }
  ];

  return (
    <section className="mt-24">
      <motion.h2 
        className="text-4xl font-chillax-bold text-white mb-12 text-center"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        Transforming Industries with ExoSuit VR
      </motion.h2>
      <div className="flex flex-col md:flex-row md:space-x-6 space-y-6 md:space-y-0">
        {industries.map((industry, index) => (
          <motion.div
            key={index}
            className="flex-1 bg-[#2a3540] p-8 rounded-2xl shadow-lg"
            variants={fadeIn}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "-100px" }}
          >
            <h3 className="text-2xl font-chillax-bold mb-4 text-white">{industry.title}</h3>
            <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed">{industry.description}</p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

export default TransformingIndustries;