// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Layout from './components/Layout';
// import Home from './pages/Home';
// import Product from './pages/Product';
// import Team from './pages/Team';
// import Vision from './pages/Vision';
// import ProductDetails from './pages/ProductDetails';
// import ExoskeletonApplications from './pages/ExoskeletonApplications';
// import ContactUs from './pages/ContactUs';
// function App() {
//   return (
//     <Router>
//       <Layout>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/product" element={<Product />} />
//           <Route path="/team" element={<Team />} />
//           <Route path="/vision" element={<Vision />} />
//           <Route path="/exoskeleton-applications" element={<ExoskeletonApplications />} />
//           <Route path="/contact-us" element={<ContactUs />} /> {/* Ensure this path is lowercase */}

//           <Route path="product/details" element={<ProductDetails />} /> {/* Ensure this path is lowercase */}
//         </Routes>
//       </Layout>
//     </Router>
//   );
// }

// export default App;



import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';

// Import pages from their new modular locations
import Home from './pages/home';
import Product from './pages/product';
import Team from './pages/team';
import Vision from './pages/vision';
import ProductDetails from './pages/product-details';
import ExoskeletonApplications from './pages/exoskeleton-applications';
import ContactUs from './pages/contact';

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product" element={<Product />} />
          <Route path="/team" element={<Team />} />
          <Route path="/vision" element={<Vision />} />
          <Route path="/exoskeleton-applications" element={<ExoskeletonApplications />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/product/details" element={<ProductDetails />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;