import React from 'react';
import { motion } from 'framer-motion';

const TechnicalInsights = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 5 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.4, ease: 'easeOut' },
    },
  };

  const technicalInsights = [
    {
      title: 'Advanced Haptic Feedback System',
      description:
        'Utilizing state-of-the-art actuators and sensors, ExoSuit VR delivers high-fidelity haptic feedback, allowing users to feel textures, forces, and vibrations with unparalleled precision. The system simulates tactile sensations ranging from subtle environmental cues to the heavy impact of virtual objects.',
    },
    {
      title: 'High-Precision Motion Capture',
      description:
        'The exoskeleton is equipped with an array of high-precision motion sensors that track user movements with sub-millimeter accuracy and minimal latency. This ensures seamless synchronization between physical actions and virtual responses, enhancing realism and reducing motion sickness.',
    },
    {
      title: 'Dynamic Load Balancing and Support',
      description:
        "ExoSuit VR intelligently distributes weight and provides support during physically demanding virtual interactions. The exoskeletons adaptive support mechanisms reduce user fatigue and prevent strain, enabling longer sessions and more intense experiences.",
    },
    {
      title: 'AI-Driven Environmental Interaction',
      description:
        'Integrated AI algorithms analyze user behavior and environmental context in real-time to adjust haptic feedback and resistance dynamically. This results in a responsive and adaptive experience where virtual environments react intelligently to user actions.',
    },
    {
      title: 'Modular and Scalable Architecture',
      description:
        'Designed with flexibility in mind, ExoSuit VR features a modular architecture that allows for easy upgrades and customization. Components such as haptic modules, sensor arrays, and support structures can be added or modified to suit specific application requirements.',
    },
    {
      title: 'Enhanced Safety Protocols',
      description:
        'Safety is paramount in ExoSuit VR. The system includes multiple layers of safety features, such as collision detection, overload protection, and emergency stop mechanisms. Real-time monitoring ensures that the exoskeleton operates within safe parameters at all times.',
    },
    {
      title: 'High-Performance Computing Integration',
      description:
        'ExoSuit VR leverages high-performance computing to process complex simulations and data in real-time. This integration enables rich, interactive environments with advanced physics, AI behaviors, and detailed graphics without compromising performance.',
    },
    {
      title: 'Comprehensive Data Analytics',
      description:
        'The system collects extensive data on user interactions, performance metrics, and system diagnostics. This data is invaluable for training assessments, performance improvements, and system maintenance, providing insights that drive better outcomes.',
    },
  ];

  return (
    <section className="mb-24">
      <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">Technical Insights</h2>
      <motion.div
        className="bg-gradient-to-br from-[#2a3540] to-[#1d2027] p-10 rounded-2xl shadow-lg"
        variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: '-50px' }}
      >
        <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-12">
          ExoSuit VR's engineering excellence lies in the seamless integration of hardware and
          software components, delivering an unmatched virtual experience:
        </p>
        <div className="space-y-10">
          {technicalInsights.map((insight, index) => (
            <div key={index} className="flex flex-col gap-3 border-l-2 border-[#E67E22] pl-6">
              <h3 className="text-xl font-chillax-bold text-[#FFFFFF]">{insight.title}</h3>
              <p className="text-[#f2f2f2] font-avenir leading-relaxed">
                {insight.description}
              </p>
            </div>
          ))}
        </div>
        <p className="mt-12 text-[#E67E22] font-avenir italic text-center">
          For a detailed breakdown of technical specifications, please contact our technical team.
        </p>
      </motion.div>
    </section>
  );
};

export default TechnicalInsights;