import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';

const Header = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  return (
    <>
      <Link to="/vision" className="flex items-center text-[#E67E22] mb-8 hover:underline">
        <ArrowLeft className="mr-2" size={20} />
        Back to Vision
      </Link>

      <motion.h1
        className="text-3xl md:text-4xl lg:text-5xl font-chillax-bold mb-6 text-white leading-snug sm:leading-tight px-4 sm:px-2 max-w-full break-words"
        variants={fadeIn}
        initial="hidden"
        animate="visible"
      >
        ExoSuit VR: Revolutionizing Physical Interaction in Virtual Environments
      </motion.h1>
      <motion.p
        className="text-xl font-avenir text-[#f2f2f2] mb-20 max-w-3xl"
        variants={fadeIn}
        initial="hidden"
        animate="visible"
        transition={{ delay: 0.2 }}
      >
        Discover how our cutting-edge force feedback exoskeleton technology is transforming virtual
        experiences across industries by enabling realistic physical interactions in digital worlds.
      </motion.p>
    </>
  );
};

export default Header;