import React from 'react';

const TeamHeader = () => {
  return (
    <>
      <h1 className="text-3xl font-chillax-bold mb-4 text-[#FFFFFF] text-center">Our Team</h1>
      <p className="text-lg font-avenir text-[#f2f2f2] mb-12 text-center">
        The visionaries behind Newton's revolutionary VR technology
      </p>
    </>
  );
};

export default TeamHeader;