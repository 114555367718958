import React from 'react';
import { Zap, Hand, Brain } from 'lucide-react';
import { motion } from 'framer-motion';

const ExplanationSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeOut" }
    },
  };

const features = [
  {
    icon: Zap,
    title: 'Cutting-Edge VR Exoskeleton',
    description: "Our proprietary exoskeleton provides precise haptic feedback and force simulation, enabling users to feel the shape, texture, and resistance of virtual objects like never before."
  },
  {
    icon: Hand,
    title: 'Realistic Physical Interactions',
    description: "Our technology allows for natural movement and manipulation within virtual spaces, making digital interactions feel as close to the real world as possible, enhancing both training and entertainment applications."
  },
  {
    icon: Brain,
    title: 'Advanced AI Integration',
    description: "Newton's adaptive AI tailors the VR experience in real-time, offering personalized feedback and continually pushing the boundaries of what immersive, physically interactive virtual reality can achieve."
  }
];

  return (
    <section className="bg-[#1d2027] py-24">
      <div className="container mx-auto px-4 md:px-6">
        <motion.h2
          className="text-3xl md:text-4xl font-chillax-bold mb-16 text-center text-white"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "-100px" }}
        >
          Revolutionizing Physical Interaction with Virtual Environments
        </motion.h2>
        <p className="text-xl text-[#f2f2f2] font-avenir leading-relaxed text-center max-w-3xl mx-auto mb-12">
          At the core of Newton's offerings is our state-of-the-art VR exoskeleton technology, which replicates real-world physical sensations and force feedback within virtual environments. This means that you don't just see and hear virtual content – you can truly feel and interact with it. Our exoskeleton enables users to experience a level of immersion that goes far beyond traditional VR, from navigating complex terrains to handling virtual tools and objects with realistic resistance.
        </p>
        <div className="grid md:grid-cols-3 gap-12">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="bg-[#2a3540] p-8 rounded-lg shadow-lg"
              variants={fadeIn}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, margin: "-100px" }}
              transition={{ delay: index * 0.1 }}
            >
              <div className="text-[#E67E22] mb-6">
                <feature.icon size={48} />
              </div>
              <h3 className="text-2xl font-chillax-bold mb-4 text-white">{feature.title}</h3>
              <p className="text-[#f2f2f2] font-avenir leading-relaxed">
                {feature.description}
              </p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ExplanationSection;