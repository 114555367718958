import React from 'react';
import { motion } from 'framer-motion';
import { faqData } from './applicationsData';

const FAQSection = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: 'easeOut' },
    },
  };

  return (
    <motion.section
      className="mt-24"
      variants={fadeIn}
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: '-100px' }}
    >
      <h2 className="text-4xl font-chillax-bold text-white mb-12">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <motion.div
          key={index}
          className="mb-8"
          variants={fadeIn}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: '-100px' }}
          transition={{ delay: index * 0.1 }}
        >
          <h3 className="text-xl font-chillax-bold text-[#E67E22] mb-2">{faq.question}</h3>
          <p className="text-md text-[#f2f2f2] font-avenir leading-relaxed">{faq.answer}</p>
        </motion.div>
      ))}
    </motion.section>
  );
};

export default FAQSection;