export const teamMembers = [
    {
      name: 'David Stahl',
      role: 'CEO & Founder',
      image: '/images/david.jpg',
      bio: 'David holds a dual degree in Economics and Business from the Hebrew University of Jerusalem, specializing in Startup Development and Machine Learning Techniques. As the visionary founder of Newton, he leads the company\'s strategic direction and business growth.',
      expandedInfo: {
        background: 'David\'s journey as an innovator began remarkably early - at age 15, he conceived and filed a patent for groundbreaking exoskeleton technology for virtual reality interaction, which was successfully granted in 2022. Originally from Brazil, his entrepreneurial spirit manifested early when he founded Evolve Midias, a music and event production company, while still a teenager. After moving to Israel in 2017, he pursued a dual degree in Economics and Business Administration at the Hebrew University of Jerusalem, combining academic excellence with his natural entrepreneurial drive.',
        innovations: [
          'Conceived VR exoskeleton technology at age 15 (2013)',
          'Successfully obtained patent approval (2022)',
          'Pioneered new approaches to virtual reality interaction',
          'Developed innovative business models for VR technology commercialization'
        ],
        expertise: [
          'Machine Learning and Big Data Analytics',
          'Neurolinguistic Programming and Conversational Hypnosis',
          'Strategic Business Development',
          'Technology Innovation Management'
        ],
        entrepreneurialHistory: [
          'Founded Newton Technologies (2023-Present)',
          'Founded Evolve Midias - Music and Event Production Company (2015-2016)',
          'Filed VR Exoskeleton Patent (2013)',
          'Patent Approval Secured (2022)'
        ],
        education: [
          'Dual Bachelor\'s Degree in Economics and Business Administration - Hebrew University of Jerusalem (Final Semester)',
          'Specialization in Startup Development',
          'Advanced studies in Machine Learning for Big Data and Data Analysis'
        ],
        languages: [
          { language: 'Portuguese', proficiency: 'Native or Bilingual Proficiency' },
          { language: 'English', proficiency: 'Full Professional Proficiency' },
          { language: 'Spanish', proficiency: 'Fluent' },
          { language: 'Hebrew', proficiency: 'Full Professional Proficiency' }
        ],
        visionAndLeadership: [
          'Transforming virtual reality interactions through innovative exoskeleton technology',
          'Leading cross-cultural team development and management',
          'Bridging technological innovation with market opportunities',
          'Developing strategic partnerships across industries',
          'Driving the company\'s vision for future growth and innovation'
        ],
        responsibilities: [
          'Leading overall company strategy and vision',
          'Managing investor relations and strategic partnerships',
          'Overseeing product development direction',
          'Building and leading high-performance teams',
          'Driving innovation and technological advancement'
        ]
      }
    },
    {
      name: 'Bruno Nigri',
      role: 'CTO',
      image: '/images/bruno-nigri.jpg',
      bio: 'Bruno\'s expertise lies in software engineering, particularly in C/C++ and embedded systems. With a background in Computer Software Engineering and Mechatronics, he leads Newton\'s technological advancements and product innovation.',
      expandedInfo: {
        background: 'As an experienced Software Engineer, Bruno brings extensive expertise in embedded systems and real-time environments. His dual educational background in Computer Software Engineering from Lev Academic Center and Mechatronics, Robotics, and Automation Engineering from Universidade Presbiteriana Mackenzie provides a unique perspective that combines software excellence with mechanical systems understanding.',
        expertise: [
          'Advanced C/C++ programming',
          'Embedded software development',
          'Real-time systems and microcontroller programming',
          'Software architecture and clean coding',
          'Problem-solving and system optimization'
        ],
        professionalHighlights: [
          'Developed physical cybersecurity solutions in real-time embedded environments at Fibernet Ltd.',
          'Created three dental laser devices with hard real-time embedded systems at Light Instruments Ltd.',
          'Achieved 50% speed increase in laser energy calibration through automation',
          'Implemented microsecond-precise timer systems for laser pulse control'
        ],
        education: [
          'Bachelor of Engineering in Computer Software Engineering - Lev Academic Center, Jerusalem',
          'Bachelor of Engineering in Mechatronics, Robotics, and Automation - Universidade Presbiteriana Mackenzie, São Paulo'
        ],
        languages: [
          { language: 'Portuguese', proficiency: 'Native or Bilingual Proficiency' },
          { language: 'English', proficiency: 'Full Professional Proficiency' },
          { language: 'Spanish', proficiency: 'Professional Working Proficiency' },
          { language: 'Hebrew', proficiency: 'Full Professional Proficiency' }
        ],
        responsibilities: [
          'Leading technological strategy and software development',
          'Overseeing embedded systems architecture and implementation',
          'Managing real-time system optimization and performance',
          'Directing software team and development processes',
          'Ensuring code quality and technical excellence'
        ]
      }
    },
    {
      name: 'Lucas Triginelli',
      role: 'Chief Product Development Officer',
      image: '/images/lucas.jpg',
      bio: 'Lucas specializes in mechanical design and product development. With a Bachelor\'s degree in Mechanical Engineering, he oversees all aspects of Newton\'s product design and development processes.',
      expandedInfo: {
        background: 'With over 5 years of experience in mechanical engineering, Lucas brings extensive expertise in mechanical design, product development, and testing. Originally from Brazil and now based in Israel for 9 years, his international background enriches our cross-cultural and multidisciplinary engineering environment. His track record includes successful product launches at Duma Optronics and significant achievements at Dentaray, where he achieved a 40% reduction in product size through mechanical design optimization.',
        expertise: [
          'Solidworks and mechanical design',
          'Product development from concept to production',
          'Tolerance analysis and materials expertise',
          'Opto-mechanical assembly processes',
          'Cross-cultural team collaboration'
        ],
        professionalHistory: [
          'Senior Mechanical Engineer at Duma Optronics (2022-Present)',
          'Mechanical Engineer at Dentaray (2018-2022)',
          'Junior Mechanical Engineer at GOL Linhas Aéreas (2012-2013)'
        ],
        education: 'Bachelor\'s Degree in Mechanical Engineering from Centro Universitario Newton Paiva, Brazil',
        languages: [
          { language: 'Portuguese', proficiency: 'Native or Bilingual Proficiency' },
          { language: 'English', proficiency: 'Full Professional Proficiency' },
          { language: 'Spanish', proficiency: 'Full Professional Proficiency' },
          { language: 'Hebrew', proficiency: 'Full Professional Proficiency' }
        ],
        responsibilities: [
          'Leading mechanical design and product development initiatives',
          'Overseeing prototype development and testing',
          'Managing manufacturing processes and quality control',
          'Coordinating with cross-functional engineering teams',
          'Driving innovation in mechanical systems and processes'
        ]
      }
    }
  ];