import React, { useEffect } from 'react';
import ProductHero from './ProductHero';
import ProductOverview from './ProductOverview';
import EngineeringSection from './EngineeringSection';
import TransformingIndustries from './TransformingIndustries';
import ProductCTA from './ProductCTA';

const Product = () => {
  // Scroll to top on component mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
      <div className="container mx-auto px-4 py-24 max-w-7xl">
        <ProductHero />
        <ProductOverview />
        {/* Horizontal Line */}
        <div className="w-full h-px bg-gradient-to-r from-transparent via-[#E67E22] to-transparent my-24"></div>
        <EngineeringSection />
        <TransformingIndustries />
        <ProductCTA />
      </div>
    </div>
  );
};

export default Product;