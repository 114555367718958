import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { motion } from 'framer-motion';

const CustomizationSection = () => {
  const customizationPoints = [
    {
      title: 'Industry-Specific Configurations',
      description: 'Tailor haptic feedback and movement parameters to suit particular training scenarios or entertainment experiences, ensuring maximum relevance and effectiveness for your users.'
    },
    {
      title: 'Seamless Software Integration',
      description: 'Integrate ExoSuit VR effortlessly with your existing VR platforms, applications, and content management systems, creating a unified and efficient workflow that enhances productivity.'
    },
    {
      title: 'Content Development Support',
      description: 'Partner with our developers to create bespoke virtual environments and simulations that leverage the full capabilities of ExoSuit VR, delivering unique and immersive experiences that set you apart from competitors.'
    },
    {
      title: 'Scalable Deployment Options',
      description: 'Whether you need a single-unit setup for specialized training or a large-scale implementation for commercial venues, we offer solutions that fit your organizational needs and budget, ensuring accessibility and scalability.'
    }
  ];

  return (
    <section>
      <h2 className="text-3xl font-chillax-bold text-[#FFFFFF] mb-8">
        Customization and Integration
      </h2>
      <p className="text-lg text-[#f2f2f2] font-avenir leading-relaxed mb-8">
        ExoSuit VR is designed with versatility in mind. Our team collaborates closely with clients
        to provide customized solutions that align with specific industry requirements and
        applications:
      </p>
      <ul className="list-disc list-inside space-y-4 text-[#f2f2f2] font-avenir mb-8">
        {customizationPoints.map((point, index) => (
          <li key={index}>
            <span className="font-semibold">{point.title}:</span> {point.description}
          </li>
        ))}
      </ul>
      <Link to="/contact-us">
        <motion.button
          className="bg-[#E67E22] text-white px-6 py-3 rounded-full hover:bg-[#D35400] transition duration-300 flex items-center text-lg font-semibold"
          whileHover={{ scale: 1.01 }}
          whileTap={{ scale: 0.99 }}
        >
          Discuss Your Project <ArrowRight className="ml-2" size={20} />
        </motion.button>
      </Link>
    </section>
  );
};

export default CustomizationSection;