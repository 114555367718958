import React from 'react';
import { ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';

const HeroSection = () => {
  return (
    <section className="relative h-screen overflow-hidden">
      <div className="absolute inset-0">
        <picture>
          <source
            media="(min-width: 768px)"
            srcSet="/images/heroImage.jpg"
          />
          <source
            media="(max-width: 767px)"
            srcSet="/images/hero image 1x1.jpg"
          />
          <img
            src="/images/hero image 1x1.jpg"
            alt="Newton VR Exoskeleton"
            className="w-full h-full object-cover md:object-contain lg:object-cover object-center"
            style={{ objectPosition: '50% 50%' }}
          />
        </picture>
      </div>
      <motion.div
        className="absolute bottom-8 left-1/2 transform -translate-x-1/2"
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        <ChevronDown className="text-white animate-bounce" size={40} />
      </motion.div>
    </section>
  );
};

export default HeroSection;