import React, { useEffect } from 'react';
import VisionHeader from './VisionHeader';
import VisionMissionSection from './VisionMissionSection';
import CoreValuesSection from './CoreValuesSection';
import Separator from './Separator';
import FutureImpactSection from './FutureImpactSection';
import FinalCTASection from './FinalCTASection';

const Vision = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-gradient-to-b from-[#1d2027] to-[#2a3540] min-h-screen text-white">
      <div className="container mx-auto px-4 sm:px-6 py-24 max-w-7xl">
        <VisionHeader />
        <VisionMissionSection />
        <CoreValuesSection />
        <Separator />
        <FutureImpactSection />
        <FinalCTASection />
      </div>
    </div>
  );
};

export default Vision;